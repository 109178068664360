.signup_section .form_block .form_side .section_title p {
    font-weight: 500;
    margin-top: 11px;
    color: #000;
}
.signup_section .form_block .form_side .section_title h2 {
    font-size: 35px;
    color: #000;
}
.signup_section .form_block .side_screen .scrren::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%) rotate(-8deg);
    width: 380px;
    height: 380px;
    background-color: #fff200 !important;
    z-index: -5;
    border-radius: 30px;
}
.signup_section .form_block {
    display: flex;
    align-items: center;
    background-color: var(--bg-white);
    border-radius: 31px !important;
}
.signup_section .form_block .form_side form .forgate_check a {
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    color: #000 !important;
}
.puprple_btn:hover {
    color: #000 !important;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}
.form_side h4,h5 {
    color: #000;
}
.form-group label {
    color: #000;
}
.section_title h2 span {
    color: #fff200;
    text-shadow: 0 0 3px black;
}
a.btn.puprple_btn.ml-0 {
    border: 1px solid #000;
}
.review_section .review_side.innerpage_block .review_block {
    width: 100% !important;
}
.buy-btn {
    width: 190px;
}
.pannel_block.aos-init.aos-animate {
    height: 400px;
}

/* testimonial */

.testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 2rem;
    background-color: #fff;
    color: #333;
    border-radius: 15px;
    margin: 20px auto;
    padding: 50px 80px;
    width: 800px;
    min-height: 430px;
    position: relative;
  }
  
  .stars {
    font-size: 14px;
  }
  
  .testimonial {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 900;
    height: 100%;
    line-height: 28px;
    margin: 0;
  }
  
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .user .user-image {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  
  .user .user-details {
    text-align: center;
  }
  
  .user .username {
    margin: 0;
    font-size: 14px;
  }
  
  .user .role {
    margin: 0;
    font-size: 12px;
  }
  
  .progress-dots {
    display: flex;
    gap: 5px;
  }
  
  .progress-dot {
    width: 5px;
    height: 5px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  .progress-dot.active {
    background-color: #555;
  }
  
  /* .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    font-size: 10px;
    cursor: pointer;
    transition: 0.1s ease;
  }
  
  .btn:hover {
    background-color: #eee;
  }
  
  #btn-prev {
    left: 25px;
  }
  
  #btn-next {
    right: 25px;
  } */
  .img-box img{
    width: 60px !important;
    height: auto  !important;
}

.img-box{
    text-align: center;
}

.conten-place .testimonial{
    margin-bottom: 0;
    text-align: left;
}
.white_text p, .white_text .section_title h2, .white_text span, .white_text .links h3 {
    color: #fff;
    font-size: 15px ;
    font-weight: 400;
    margin-bottom: 50px;
    padding-top: 10px;
}
.carousel-control-next img {
    height: 20px;
    width: auto;
}
.carousel-control-prev img{
    height: 20px;
    width: auto;
    
}
.carousel-control-prev {
    left: -32px !important;
}
.carousel-control-next {
    right: -21px;
}
.buy-btnn {
    width: 500px;
    font-size: 25px !important;
}