.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.review_block {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Adjust the transition timing as needed */
}

.review_block.visible {
  opacity: 1;
}


.choose-us {
  position: relative;
}
/* Add this CSS to your stylesheet */
.text_list_section:hover .slide-block {
  animation-play-state: paused;
}

.background-line {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 0;
  width: 100%;
  height: 2px; /* Adjust as needed */
  background-color: #000; /* Adjust background color as needed */
  z-index: 1;
}

.text-container {
  position: relative;
  z-index: 2;
}
/* Add this CSS to your existing stylesheet */
.slider_block {
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Hides any overflowing content */
}

/* Define the animation */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Apply the animation to the slider */
.slide-block {
  animation: slide 20s linear infinite; /* Adjust duration as needed */
}

.slick-slide img {
  display: block;
  height: 451px;
}

.scroll-arrow {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff200; /* Adjust the background color as needed */
  color: #000; /* Adjust the color as needed */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out;
}

.scroll-arrow:hover {
  opacity: 0.8;
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.rbi-logo{
  height: 50px;
  width: 50px;
}
.makein-india-logo{
  width: 70px;
  height: 30px;
  margin-bottom: 50px;
}
.startup-logo{
  width: 150px;
  height: 30px;
  margin-bottom: 50px;
}
.abt_slides img{
  border-radius: 12px;
}
.abt_slides{
  gap: 10px;
}
#about-image{
  height: auto !important;
}
.modal{
  height: 200px;
  width: 200px;
}
.counter{
  z-index: 999;
}
.m-cancel-btn{
  width: 30px !important;
  position: absolute !important;
  top: 0px !important;

  left: 94% !important;
  background: transparent !important;
  color: white !important;
border-radius: 0px 12px 0px 0px !important;
padding: 0  !important;
font-size: 19px !important  ;
border: 0 !important;
}
.modal-custom {
  width: 500px;
  height: 300px;
  margin: auto;
  z-index: 999999;
  position: absolute;
  top: 18%;
  left: 32%;
}
.counter h3{
  font-size: 25px;
}
.signup_section .form_block .form_side_forgot {
  width: 100% !important;
  text-align: center !important;
  padding: 69px 59px !important;
} 
.form-block-forgot{
  width: 50% !important;
  margin-left: 25% !important;
}
.modal-1{
   
    position: absolute !important;
    top: 50% !important;
    left: 0% !important;
    z-index: 1060;
    display: block;
    width: 100%;
    height: 100%;
    /* overflow-x: hidden; */
    overflow-y: hidden;
    outline: 0;
    scroll-behavior: unset;

}
.modal-1 .modal-body{
  color: black;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
  min-width: 100%;
  top: 70%;
}

.tabs button{
  border: none;
  padding: 10px 100px;
  /* margin-left: 17px; */
  border-radius: 25px;
  margin-bottom: 12px;
}
.tabs button.active {
  background-color: #fdf401; /* Change to your desired background color */
  color: #000; /* Change to your desired text color */
}
#assist-plus-btn:hover{
color: #fff !important;
}

.vertical-scroller-container {
  overflow: hidden;
  height: 300px; /* Adjust as needed */
  justify-content: center;
  display: flex;
  font-size: 16px;
  margin: 23px 0px
}
.vertical-scroller:hover {
  animation-play-state: paused;
  cursor: pointer;
}


.vertical-scroller {
  list-style-type: none;
  padding: 0;
  margin: 0;
  animation: scroll 10s linear infinite; /* Adjust duration as needed */
}

.vertical-scroller li {
  line-height: 40px;
  border-style: solid;
  padding: 0px 25px;
  border: 1px dashed #24241b;
  border-radius: 7px;
  font-family: emoji;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}


