/*homepage css new*/

.banner_section {
    position: relative;
    padding-top: 150px !important;
    /* background: rgb(251,88,113); */
    /* background: linear-gradient(63deg, rgba(251,88,113,1) 0%, rgba(252,129,93,1) 100%); */
    background: linear-gradient(63deg, rgb(0 0 0) 0%, rgb(0 0 0) 100%);
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
}
.line:nth-child(1) {
    margin-left: -40%;
}
.line:nth-child(2) {
    margin-left: -20%;
}
.line:nth-child(3) {
    margin-left: 20%;
}
.line:nth-child(4) {
    margin-left: 40%;
}
.line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.2);
    overflow: hidden;
}
.line:nth-child(1)::after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: drop 7s 0s infinite;
    animation: drop 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.banner_section .banner_text {
    margin-top: 10px;
    text-align: center;
    padding: 0 150px;
}
.banner_section .banner_text::before {
    content: "";
    position: absolute;
    right: 0;
    top: 40%;
    width: 29px;
    height: 29px;
    /* background-image: url(../images/particle1.png); */
}
.banner_section .banner_text h1 {
    font-size: 70px;
    color: #fff200;
    /* color: var(--text-white); */
    letter-spacing: -1.5px;
    font-weight: 700;
    margin-bottom: 20px;
}
.banner_section .banner_text h1 span {
    color: #fff200;
    /* color: var(--text-white); */
}
.banner_section .banner_text::before {
    content: "";
    position: absolute;
    right: 0;
    top: 40%;
    width: 29px;
    height: 29px;
    /* background-image: url(../images/particle1.png); */
}
.banner_section .banner_text p {
    padding: 0 150px 30px 150px;
    color: var(--text-white);
}
.left_space {
    margin-left: 20px;
}
.btn_bottom {
    z-index: 1;
    border: 1px solid #ffffff;
    /* border: 1px solid var(--primery); */
    border-radius: 100px;
    position: absolute;
    top: -8px;
    left: 8px;
    right: -12px;
    transform: translate(-0.52em, 0.52em);
    width: 100%;
    height: 100%;
}
.white_btn .btn_bottom {
    border-color: var(--bg-white);
}
.hero_image {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 50px;
}
.hero_image.mobile {
    display: none;
}
header {
    position: relative;
    width: 100%;
    z-index: 1;
    transition: .4s all;
    /* border-bottom: 1px solid rgba(255, 255, 255, .2); */
}
.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.banner_section .banner_text h1 {
    font-size: 50px;
}

/*app screen*/

.section-header {
    margin-bottom: 80px;
    text-align: center;
}
.section-header h2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 48px;
    color: #fff200 !important;
    margin-bottom: 15px;
    text-transform: capitalize;
}
.section-header p {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
}
.no-js .owl-carousel, .owl-carousel.owl-loaded {
    display: block;
}
.owl-carousel {
    display: block;
    width: 100%;
    z-index: 1;
}

#appScreenshots {
    padding: 200px 0;
    background: #000;
    position: relative;
}

.list_screen_slide::after {
    /* content: url("../images/new-home/phone.png"); */
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-51.4%, -48.9%);
    transform: translate(-50%, -48.9%)
}
.list_screen_slide .item img {
    display: block;
    margin: auto;
    width: auto;
    max-width: 100%;
    border-radius: 20px;
    border: 2px solid #eee;
}
.list_screen_slide .owl-item .item img {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.list_screen_slide .owl-item.center .item img {
    -webkit-transform: scale(1);
    transform: scale(1);
}
.list_screen_slide .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -130px;
}


/*app screen section*/
 .swiper-container {
      width: 100%;
      height: 100%;
      
    }


.swiper-full-mobile {
    position: relative;
    margin: 50px auto;
    padding-bottom: 50px;
}

.swiper-full-mobile:befores {
    content: "";
    width: 265px;
    background: url(http://md-aqil.github.io/images/Pixel-3-XL.png);
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    height: 565px;
    z-index: 2;
    margin: auto;
}
.swiper-full-mobile .swiper-slide {
  height: 100%;
}
.swiper-full-mobile .swiper-slide img{
  width: 100%;
  height: 530px;
  object-fit: cover;

}
.swiper-slide.swiper-slide-active img {
  border-radius: 00px;

}
.swiper-button-prev,.swiper-button-next {
    filter: sepia(1) brightness(2);
}
.swiper-pagination-bullet {
    background: #fff200 !important;
}
.align-setfr {
    margin-top: -5px !important;
}