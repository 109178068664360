













/*:root {
  --smaller: .75;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
}*/

.counter  {
  align-items: center;
  background-color: #181a22;
  /*display: flex;*/
  height: 70vh;
  margin: 0;
  text-align: center;
  padding:25px 0px;
  z-index: 999999999999999999 !important;
  border-radius: 12px;
  border: 0;
}



.counter h1 {
  font-weight: normal;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

.counter li {
  display: inline-block;
  font-size: 1.0em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

.counter li span {
  display: block;
  font-size: 3.0rem;
}

.counter .emoji {
  display: none;
  padding: 1rem;
}

.counter .emoji span {
  font-size: 4rem;
  padding: 0 .5rem;
}

@media all and (max-width: 768px) {
  .counter h1 {
    font-size: calc(1.5rem * var(--smaller));
  }
  
  .counter li {
    font-size: calc(1.125rem * var(--smaller));
  }
  
  .counter li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}

.counter ul li{
    color:#fff200;
}
.counter h1{
    color:#fff200;
}

.counter a{
    text-decoration:none;
}

.counter button{
    transform: scale(1);
    transition: 0.2s;
    padding: 7px 30px;
    border-radius: 8px;
    font-size: 25px;
    cursor: pointer;
    background-color:#fff200;
}

.counter button:hover{
    transform: scale(1.1);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.counter img{
    width:auto;
    height:120px;
}

.counter h4{
    color:#fff200;
}