/* Extra Small Devices (Phones) */
@media (max-width: 321px) {
  .slick-slide img {
    height: 270px !important;
  }

  footer {
    padding: 25px 0px !important;
  }

  .useful {
    margin-left: 40px;
  }

  .navbar-brand {
    margin-bottom: 10px !important;
    text-align: start;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .social_media {
    margin-top: 10px !important;
    margin-left: 15px;
  }

  .payment_section .pay_btn li {
    margin-right: 3px;
  }

  footer .links ul li a {
    font-size: 12px !important;
  }
  .footer-sec{
    font-size: 12px !important;
  }
  .img-box img{
    height: auto !important;
  }
  #sell-btn {
    width: 245px;
    font-size: 16px !important;
  }
  .modal-custom{
    width: 285px !important;
  }
  .counter img {
    width: auto;
    height: 60px !important;
}
.counter li span {
  font-size: 20px;
}
.counter li {
  padding: 4px !important;
}
}

@media (min-width: 322px) and (max-width: 376px) {
  .slick-slide img {
    height: 335px !important;
  }
  .social_media {
    margin-top: 10px !important;
    margin-left: 15px;
  }

  .payment_section .pay_btn li {
    margin-right: 3px;
  }

  .footer-sec{
    font-size: 12px !important;
  }
  .banner_section .banner_text h1 {
    font-size: 28px;
}
footer {
  padding: 25px 0px !important;
}
.useful {
  margin-left: 30px;
}
#sell-btn {
  width: 275px;
  font-size: 18px !important;
}
.img-box img{
  height: auto !important;
} 

}
@media (min-width: 377px) and (max-width: 431px) {
  .slick-slide img {
    height: 335px !important;
  }
  .social_media {
    margin-top: 10px !important;
    margin-left: 15px;
  }

  .payment_section .pay_btn li {
    margin-right: 3px;
  }

  .footer-sec{
    font-size: 12px !important;
  }
  .banner_section .banner_text h1 {
    font-size: 28px;
}
footer {
  padding: 25px 0px !important;
}
.hero_slider{
  margin-right: 12px;
}
#sell-btn{
  width: 270px !important;
  font-size: 19px !important;
  
}
.img-box img{
  height: auto !important;
}
.modal-custom{
  left: 11% !important;
  top: 18% !important;
}

}

@media (max-width: 575.98px) {

  /* CSS rules for extra small devices */
  .desk-header {
    display: none;
  }

  .banner_section .banner_text p {
    padding: 0px;
  }

  .bner-txt {
    margin-top: 0px;
  }

  .banner_section {
    padding-top: 40px !important;
  }

  .navbar-brand img {
    height: 70px;
  }

  header {
    top: 0;
  }

  .lines {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;

  }

  /* .slick-prev:before{
  position: absolute !important;
  left: 12px !important;
  z-index: 9999 !important;
} */
  .slick-slide img {
    margin-left: 0px !important;
    height: 390px;
  }

  .col-12 {
    width: 50% !important;
  }

  footer .social_media li a {
    line-height: 22px !important;
  }

  footer .social_media li a {
    width: 25px;
    height: 25px;
    border: 1px solid var(--dark-greay);
    border-radius: 100px;
    color: var(--text-white);
    display: block;
    text-align: center;
    line-height: 35px;
    transition: .4s all;
  }

  .navbar-brand img {
    height: 64px;
  }

  .links {
    text-align: start;
  }

  .white_text p,
  .white_text .section_title h2,
  .white_text span,
  .white_text .links h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 4px;
    padding-top: 10px;
  }

  .payment_section .pay_btn img {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid rgba(255, 255, 255, .3);
    border-radius: 50px;
    transition: .4s all;
    background-color: #fff;
  }

  .payment_section .pay_btn {
    display: flex;
    list-style: none;
    margin-top: 0;
    padding: 0;
    justify-content: center;
  }

  .payment_section {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: start;
  }

  .rbi-logo {
    height: 25%;
    width: 20%;
  }

  .makein-india-logo {
    width: 25%;
    height: 30%;
    margin-bottom: 0px;
  }

  .startup-logo {
    width: 75%;
    height: 30%;
    margin-bottom: 0px;
  }

  .hero_slider {
    margin-left: 12px;
  }
  .pricing_inner .pannel_block{
    text-align: center;
  }
  .buy-btnn {
    width: 343px;
    font-size: 25px !important;
}
.modal-custom {
  width: 320px;
  height: 300px;
  margin: auto;
  z-index: 999999;
  position: absolute;
  top: 24%;
  left: 6%;
}
.counter img {
  width: auto;
  height: 85px;
}
.counter li{
  padding: 6px;
}
.counter{
  height: 50vh;
}
.m-cancel-btn{
  left: 91% !important;
}
}

/* Small Devices (Tablets, Phablets) */
@media (min-width: 576px) and (max-width: 767.98px) {

  /* CSS rules for small devices */
  .desk-header {
    display: none;
  }
  .modal-custom {
    width: 500px;
    height: 300px;
    margin: auto;
    z-index: 999999;
    position: absolute;
    top: 24%;
    left: 16%;
  }
}

/* Medium Devices (Desktops, Laptops) */
@media (min-width: 768px) and (max-width: 991.98px) {

  /* CSS rules for medium devices */
  .desk-header {
    display: none;
  }

  .payment_section .pay_btn {
    margin-top: 20px;
    justify-content: start;
  }

  .white_text .links h3,
  .white_text span {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .links {
    margin-top: 12px;
  }

  .signup_section .top_part {
    margin-top: 4rem !important;
  }

  .buy-btn {
    width: 120px;
  }
  .modal-custom {
    width: 500px;
    height: 300px;
    margin: auto;
    z-index: 999999;
    position: absolute;
    top: 24%;
    left: 16%;
  }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
  .mobile-header {
    display: none;
  }

  .slick-slide img {

    height: 360px;
  }

  .banner_section .banner_text {
    margin-top: -20px;
  }

  .puprple_btn {
    margin-left: -23px;
    margin-top: -5px;
  }

  .white_text p {
    margin-bottom: 36px;
  }
}

/* Extra Large Devices (Large Desktops, TVs) */
@media (min-width: 1200px) {
  .mobile-header {
    display: none;
  }
}